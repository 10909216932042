:root {
  --primaryYellow: #f7921f;
  --swiper-theme-color: #f7921f;
  --swiper-navigation-size: 20px;
}

.slider_container .swiper-container {
  width: 100%;
  overflow: hidden;
  margin-top: 5%;
  margin-left: 5%;
  position: relative;
}
.slider_card_text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  height: 3em;
  --webkit-line-clamp: 2;
  --webkit-box-orient: vertical;
}
.slider_card {
  width: 13rem;
  border-radius: 18px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.7rem;
  padding: 2em 1em;
  flex-direction: column;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  -ms-border-radius: 18px;
  -o-border-radius: 18px;
}

.feature-content p {
  color: var(--brand-color) !important;
  font-family: "Poppins";
  font-weight: bolder;
  letter-spacing: 1px;
  margin-bottom: 5px;
  padding-top: 20px;
}

.swiper-slide .card-img-top {
  width: 100px !important;
}
/* Swiper slides */
.slider_container .swiper-slide {
  display: flex;
  justify-content: center;

  align-items: center;
  border-radius: 5px; /* Optional: Add border radius */
  font-size: 24px; /* Font size for slide content */
  color: #333; /* Text color for slide content */
}
.slider_container .custom-next-arrow,
.slider_container .custom-next-arrow {
  position: absolute !important;
  margin-top: 3.2rem;
  font-size: 20px; /* Font size for navigation buttons */
  color: var(--swiper-theme-color); /* Color for navigation buttons */
  border: 2px solid var(--primaryYellow); /* Border for navigation buttons */
  border-radius: 50%; /* Optional: Add border radius to make them round */
  width: 40px; /* Width of navigation buttons */
  height: 40px; /* Height of navigation buttons */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 2rem !important;
  margin-left: 1rem !important;
  transition: background-color 0.3s ease; /* Optional: Add transition effect */
  bottom: 5%;
  user-select: none;
}

.slider_container .custom-next-arrow {
  right: 10%;
  bottom: 0;
}
.slider_container .custom-prev-arrow {
  right: 15%;
  bottom: 0;
}
/* Navigation buttons */
.custom-next-arrow,
.custom-prev-arrow {
  position: relative !important;
  margin-top: 3.2rem;
  font-size: 20px; /* Font size for navigation buttons */
  color: var(--swiper-theme-color); /* Color for navigation buttons */
  border: 2px solid var(--primaryYellow); /* Border for navigation buttons */
  border-radius: 50%; /* Optional: Add border radius to make them round */
  width: 40px; /* Width of navigation buttons */
  height: 40px; /* Height of navigation buttons */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 2rem !important;
  margin-left: 1rem !important;
  transition: background-color 0.3s ease; /* Optional: Add transition effect */
  bottom: 5%;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: #007bff; /* Hover background color */
  color: #fff; /* Hover text color */
}
.custom-prev-arrow {
  float: right;
  left: auto;
}
.custom-next-arrow {
  left: auto;
  float: right;
}
/* .slider_container .swiper-slide .card-img-top {
  margin-top: 15px;
} */
.slider_card_img {
  width: 4rem;
  height: 6em;
  margin-bottom: 1.3em;
}
.slider_container {
  height: fit-content;
  background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
  background-size: cover; /* Adjust the background size as needed */
  background-position: center;
  background-image: url("../../assets/Group1857.png") !important;
  position: relative;
}

/* Your existing CSS rules */

@media screen and (max-width: 768px) {
  .swiper-slide .card {
    width: 40%; /* Adjust the width as needed for two cards in a row */
    margin: 0 10px; /* Add some spacing between cards */
  }
}

@media screen and (max-width: 480px) {
  .swiper-slide {
    width: 185px !important; /* Adjust the width as needed for two cards in a row */
  }

  .slider_container .custom-prev-arrow {
    right: 30%;
    bottom: 0;
  }
  .slider_container .swiper-container {
    margin-top: 0%;
    margin-left: 0%;
  }
  .slider_container {
    height: fit-content;
  }
}
