
.header {
  margin: 0!important;
  padding: 0 !important;
  background-color: #f7921fba;

}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.1em !important;
  height: 1.2em !important;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  outline: none;
}

.header_inner{
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  padding-top: 20px; 
  opacity: 1;
  padding-bottom: 20px;
  /* Add other styles for the .container-fluid if necessary */
}
.brand{
  /* margin-left: 50px!important; */
  color: var(--brand-color);
  font-size: 1.5rem;
  text-transform: none;
  text-decoration: none;
  text-transform: uppercase;
  font-family: var(--font-family);
  font-weight: 600;
}

/* #navbarNav{
  margin-left: 350px;
} */
.navbar-brand {
  margin-left: 50px !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600; /* Apply bold (600) weight */
  font-size: 30px;
  text-transform: uppercase;
  color: #FF0000;
}

.nav-link {
  margin-right: 30px; /* Adjust the margin to increase or decrease the gap */
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600; /* Apply bold (600) weight */
  text-decoration: none; /* Remove default underline */
  color: #000; /* Set default color */
  position: relative; /* Add relative positioning */
}

.nav-link:hover {
  color: black /* Change text color on hover */
}

/* Add the red underline effect */
.nav-link::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px; 
  background-color: var(--brand-color); 
  height: 1px; 
  transform: scaleX(0); 
  transition: transform 0.3s ease; 
}
.nav-link{
  margin-right:0px;
  margin-left: 2rem;
}

.nav-link:hover::after {
  transform: scaleX(1); 
}


.nav-link{
  font-weight: bolder!important;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .navbar-toggler {
    display: block; /* Show the toggle button for smaller screens */
  }

  .navbar-collapse {
      display: flex; /* Center-align the navigation links */
    justify-content: center; /* Center horizontally */
    margin: 0!important;
  }

  .navbar-toggler-icon {
    display: block; /* Show the toggler icon */
  }

  .navbar-toggler.collapsed + .navbar-collapse {
    display: none; /* Hide the navigation links when the toggler is collapsed */
  }

  .navbar-toggler.collapsed {
    display: block; /* Show the toggler button when collapsed */
  }
}

@media (max-width: 480px) {
  .navbar-toggler {
    display: block;
  }
  .navbar-toggler:focus {
    outline: none!important;
  }

  .navbar-collapse {
    display: flex;
    justify-content: center;
    margin: 0 !important;
    background-color: #f7921f; /* Background color for mobile navbar */
    position: absolute;
    top: 60px; /* Adjust the top position as needed */
    left: 0;
    right: 0;
    z-index: 1; /* Ensure it appears above content */
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
  }

  .navbar-toggler-icon {
    display: block;
  }

  .navbar-toggler.collapsed + .navbar-collapse {
    display: none;
  }

  .navbar-toggler.collapsed {
    display: block;
  }

  /* Adjust the mobile navbar links */
  .nav-link {
    margin: 10px 0;
    text-align: center;
    font-size: 18px;
  }

  .nav-link::after {
    bottom: -2px;
  }
}