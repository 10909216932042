
.details-container{
    display: flex;
    flex-direction: column;
    align-items: center;
   
    margin-bottom: 50px;
    width: 70%;
    box-shadow: 0px 2px 5px 0px #00000029;
    margin-left: 200px!important;
    font-family: 'Poppins'!important;
}
.booking-banner{
    background-color: #f7921f;
    color:white;
    font-family: 'Poppins';
    width: 195px;
    height:50px;
    margin-top: 50px;
    margin-left: 200px;
    position: sticky;
    font-family: 'Poppins';
}

.booking-banner-content{
    display: flex;
    padding-top: 10px;
    
}
.booking-banner-content img{
    width: 35px;
    margin-right: 10px;
    margin-left: 10px;
}
.booking-no{
   margin-top: 30px;
    width: 70%;
   margin-bottom: 20px;
   padding-top: 10px;
   padding-left: 10px;
   padding-bottom: 10px;
   text-align: left;
   padding-right: 10px;
}
.booking-no img{
    height: 50px;
    width: 50px;
}
.booking-confirmation{
    width: 70%;
    border: 1px solid #A7A7A7; 
   margin-bottom: 20px;
   padding-top: 10px;
   padding-left: 20px!important;
   padding-bottom: 10px;
   text-align: left;

}

.contact-information{
    width: 70%;
    border: 1px solid #A7A7A7; 
   margin-bottom: 20px;
   padding-top: 10px;
   padding-left: 10px!important;
   padding-bottom: 10px;
   text-align: left;

}
.payment-method{
    width: 70%;
    border: 1px solid #A7A7A7; 
   margin-bottom: 20px;
   padding-top: 10px;
   padding-left: 10px!important;
   padding-bottom: 10px;
   text-align: left;
    
}
.payment-method img{
    height: 30px;
    width: 60px;
}
.grand-total{
    width: 70%;
    border: 1px solid #A7A7A7;
   margin-bottom: 30px;
   padding-top: 10px;
   padding-left: 10px!important;
   padding-bottom: 10px;
   text-align: left;
   color: #f7921f;
}
@media (max-width: 768px) {
    .details-container {
        width: 90%; /* Adjust the width as needed */
        margin-left: 5%; /* Center the container */
    }
    .booking-banner {
        margin-left: 5%; /* Center the banner */
    }
    /* Add more responsive styles as needed */
}

/* Responsive styles for screens with a max-width of 480px */
@media (max-width: 480px) {
    .details-container {
        width: 100%; /* Adjust the width as needed */
        margin-left: 0px!important; /* Center the container */
    }
    .booking-banner {
        margin-left: 0px; /* Center the banner */
    }
    /* Add more responsive styles as needed */
}


