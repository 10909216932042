.package_section {
  position: relative;
  margin-top: 5rem;
}

.package-details {
  display: grid;
  grid-template-columns: 60% 40%;
  gap: 30px;
}
.package-details .card{
  border: 0px !important;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

.detail-banner {
  font-family: "Poppins";
  width:fit-content;
  background-color: var(--primary-color);
  color: white;
  padding: 15px 20px;
  font-size: 1.2rem;
}
.form-control::placeholder {
  font-family: "Poppins"; /* Change to your desired font-family */
  color: grey; /* Change to your desired color */
  font-size: 14px;
}
/* .arrival-date input {
  margin-left: 40px;
  width: 87%;
} */

label{
  color: var(--primary-color);
  margin-bottom: 0.5em;
}
.arrival-date{
  margin-bottom: 0.8em;
}

.detail-banner p {
  text-align: center;
  padding-top: 10px;
}
.packagedetails-subcontainer-cards {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-right: 0.5px solid var(--border-color);
  border-left: 0.5px solid var(--border-color);
  border-bottom: 0.5px solid var(--border-color);
  padding: 1.2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.packagedetails-subcontainer-cards img {
  width: 2.5rem;
  height: 2.5rem;
  margin-bottom: 1rem;
}
.packagedetails-subcontainer-cards p {
  margin: 0;
  font-size: 0.8rem;
}
/* .packagedetails-subcontainer-cards p {
  padding-top: 10px;
  font-size: 12px;
} */
.package-detail-cards {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
/* .package-details {
  display: flex !important;
  justify-content: center;
  margin-left: 20px;
} */

.days {
  color: #f7921f;
  margin-right: 20px;
}

.package-details .card-text {
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  display: grid;
  grid-template-columns: 17% 80%;
  /* gap: 1em; */
}
.package-details .card{
 padding: 20px !important;
}
.package-details .card-subtitle {
  margin-bottom: 25px;
  color: #ff0000;
  padding-top: 25px;
}
.package-details .card-text p {
  font-weight: 500 !important;
  font-family: "Poppins" !important;
  line-height: 30px;
  color: black;
  font-size: 15px;
  text-align: left;
  padding-right: 20px !important;
  padding-left: 20px;
}
/* .booknow-button {
  margin-top: 50px !important;
  margin-left: 30px;
} */
.booknow-button  {
  background-color: #f7921f;
  color: white;
  font-family: "Poppins";
  font-size: 26px;
  width: 100%;
  height: 75px;
  border: none;
  border-radius: 18px;
  margin-top: 1em;
}
.booknow-image  {
  width: 100%;
  margin-top: 2em;
}

.include-container {
 margin-top: 4em;
 margin-bottom: 4em;
}
.include-container img {
  margin-right: 40px;
  width: 16px;
}
.include-container h1,
span {
  font-family: "Poppins";
}
.include-container h1 {
  font-weight: 600;
  color: #f7921f;
  padding-bottom: 20px;
}
.include-container p {
  font-weight: 500;
  letter-spacing: 1px;
}
.include-content {
  display: flex;
  align-items: center;
  margin-bottom: 0.8em;
}
/* For screens 768px and larger */
@media screen and (max-width: 768px) {
  .detail-banner {
    margin-left: 0;
  }

  /* .packagedetails-subcontainer-cards p {
    text-align: center;
    font-size: 10px;
  } */
/* 
  .package-details .col-md-5 {
    margin-bottom: 0px;
  } */

  .schdule {
    margin-top: 50px;
  }

  .schdule .card-body {
    margin-left: 0;
    padding-right: 20px;
    text-align: left;
  }

  .schdule .card-text {
    font-size: 16px;
  }

  .booknow-image img {
    margin-top: 0;
  }

  .include-container {
    margin-left: 20px;
  }

  .include-subcontainer p {
    font-size: 16px;
  }

  .include-subcontainer h1 {
    font-size: 30px;
  }

  .arrival-date .form-control::placeholder {
    margin-left: 30px;
  }
}

/* For screens 480px and larger */
@media screen and (max-width: 480px) {
  .package-details {
    grid-template-columns: 100%;
    gap: 30px;
}
.packagedetails-subcontainer-cards:nth-child(2) {
  border-right: 0.5px solid var(--border-color) !important;
}
  /* .packagedetails-subcontainer-cards {
    width: 100%; 
    max-width: 260px; 
    margin-bottom: 20px;
    height: 140px;
  } */
  .detail-banner {
    margin-left: 0px;
  }
  /* .packagedetails-subcontainer-cards p {
    text-align: center;
    font-size: 10px;
  } */

  .schdule {
    margin-top: 50px;
  }
  .schdule .card-body {
    margin-left: 35px;
    padding-right: 60px;
    text-align: left;
  }
  .schdule .card-text {
    font-size: 16px;
  }

  .booknow-button {
    margin-top: 0px !important;
  }
  .booknow-image img {
    margin-top: 0px !important;
  }

  .include-container {
    margin-left: 20px !important;
  }
  .include-subcontainer p {
    font-size: 16;
  }
  .include-subcontainer h1 {
    font-size: 40px;
    margin-top: 50px;
  }
  .arrival-date .form-control::placeholder {
    margin-left: 30px !important;
  }
  /* Add any other necessary styles for smaller screens here */
}
