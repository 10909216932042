@font-face {
  font-family: Imported;
  src: url(../../../font/Wide-Latin-Regular.ttf);
}
.tourpackage-container {
  background-color: var(--card-color);
}
.tourpackage_grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 30px;
}

.title1-3 {
  font-family: "Poppins" !important;
}

.tourpackage-content p {
  color: var(--brand-color);
  font-family: "Poppins";
  font-weight: bold;
}

.packages {
  font-family: "Poppins";
  padding: 3.7rem 3.7rem;
  border: 0px !important;
  border-radius: 30px !important;
  -webkit-border-radius: 30px !important;
  -moz-border-radius: 30px !important;
  -ms-border-radius: 30px !important;
  -o-border-radius: 30px !important;
}
.packages .card_package_body {
  text-align: left;
}
.title-1 h1 {
  text-align: left;
  color: #f7921f;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 40px;
}

.timer_card_time {
  font-size: 2rem;
  /* font-family: "Imported"; */
  color: black;
  line-height: 0.8;
  font-weight: 600;
  font-family: "Poppins";
}
.slash {
  width: 20px;
  height: 70px;
}
.timer_card_days {
  font-size: 1.7rem;
  font-family: "Poppins";
  font-weight: 600;
  color: black;
}
.title1-2 {
  font-size: 80px;
  /* font-family: "Imported"; */
  color: black;
  font-family: "Poppins";
}
.packages .card-subtitle {
  margin-top: 25px;
  margin-bottom: 25px;
  color: var(--brand-color);
}
.packages .card-text {
  font-weight: 500 !important;
  font-family: "Poppins" !important;
  line-height: 25px;
  color: black;
  font-size: 15px;
  text-align: justify;
  padding-top: 0px;
}
.packages button {
  color: white;
  background-color: var(--primary-color);
  border-radius: 8px;
  border: none;
  width: 100%;
  padding: 1rem 0.75rem;
  font-family: "Poppins";
  font-weight: 500;
  margin-right: 10px;
  font-size: 0.89rem;
  text-transform: capitalize;
}

.tourpackage-content {
  text-align: center !important;
}

.card-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.detailed-buttons {
  margin-top: 25px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1em;
}
.card-title {
  margin: 0; /* Remove default margins */
}

/* Media Query for Smaller Screens (up to 480px) */
@media (max-width: 480px) {
  .tourpackage_grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 30px;
  }

  .slash {
    width: 12px;
    height: 40px;
  }
  .timer_card_days {
    font-size: 1rem;
  }
  .packages button {
    padding: 0.5rem 0.75rem;
  }
  .packages {
    font-family: var(--font-family);
    padding: 2rem 2.7rem;
    border: 0px !important;
    border-radius: 30px !important;
    -webkit-border-radius: 30px !important;
    -moz-border-radius: 30px !important;
    -ms-border-radius: 30px !important;
    -o-border-radius: 30px !important;
  }
  .packages .card-subtitle {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 0.9rem;
    color: var(--brand-color);
  }
}
