/* homepage.css */

/* Apply box-sizing to all elements */
* {
  box-sizing: border-box;
}

/* Remove margin and padding from body */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* Ensure full width for all components */
.home-container {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .home-container {
    overflow-y: hidden; /* Hide vertical scrollbar on small screens */
  }
}
