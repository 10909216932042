@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400;1,500;1,600;1,700;1,900&display=swap");

:root {
  --card-color: #f5f5f5;
  --primary-color: #f7921f;
  --border-color: #00000029;
  --primaryYellow: #f7921f;
  --swiper-theme-color: #f7921f;
  --swiper-navigation-size: 20px;
  --input-border-color: #5d5d5d;
  --brand-color: #ff0000;
  --font-family: "Poppins", sans-serif;
}
* {
  font-family: "Poppins", sans-serif;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 99;
}
.setStyle2 {
  height: 60vh;
  font-size: 7rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-shadow: 1px 2px 3px #f7921f;

  color: #000000;
}
.main_container {
  box-sizing: border-box;
  padding-top: 5.5rem;
  padding-bottom: 5.5em;
}
.inner_container {
  width: 85%;
  margin: 0 auto;
  box-sizing: border-box;
}

.border-right-0 {
  border-right: 0px !important;
}

.box_shadow {
  box-shadow: 0px 13px 10px var(--border-color);
}
.m_t_2 {
  margin-top: 3.2rem;
}
.m_b_2 {
  margin-bottom: 3.2rem;
}
.w_50 {
  width: 50%;
}
.text_primary {
  color: var(--primary-color);
}

/* Checkbox container */
.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px;
  color: black !important;
  font-weight: 300;
  font-size: 0.9rem !important;
}

/* Hide the default checkbox */
.checkbox-container input[type="checkbox"] {
  display: none;
}

/* Custom checkbox style */
.checkmark {
  width: 17px;
  height: 17px;
  border: 2px solid var(--primary-color); /* Border color */
  border-radius: 4px; /* Rounded corners */
  margin-right: 8px;
  color: black !important;
}

/* Checkbox label text */
.checkbox-container span {
  display: inline-block;
  vertical-align: middle;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 0.9rem;
  padding: 0px;
}

/* Checkbox label text when checked */
.checkbox-container input[type="checkbox"]:checked + .checkmark {
  background-color: var(--primary-color); /* Background color when checked */
  border-color: var(--primary-color); /* Border color when checked */
}

.main_heading {
  color: var(--primary-color);
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 2rem;
  text-decoration: none;
  text-transform: capitalize;
}

@media (max-width: 868px) {
  .inner_container {
    width: 90%;
  }
  .w_50 {
    width: 100% !important;
  }
}

@media (max-width: 608px) {
  .main_container {
    padding-top: 2.5rem;
    padding-bottom: 2.5em;
  }
  html {
    font-size: 80%;
  }
  .inner_container {
    width: 90%;
  }

  .w_50 {
    width: 100% !important;
  }

  .m_t_2 {
    margin-top: 2.2rem;
  }
  .m_b_2 {
    margin-bottom: 2.2rem;
  }
  .main_heading {
    font-size: 1.8rem;
  }
}
