.temple_grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 5em;
}
.grid_item_1 {
  background: url("../../assets/template.png");
  min-height: 20rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.grid_item_ts {
  min-height: 20rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.temple-content p {
  color: #ff0000;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 600;
}

.temple-content .notbold {
  font-weight: 500;
  font-family: "Poppins" !important;
  line-height: 30px;
  color: black;
  text-align: justify;
  font-size: 1rem;
}
.nankana {
  background-image: url("../../assets/Group1857.png");
  background-size: cover;
  background-position: center;
}
.w_50 {
  width: 50%;
}

.nankana p {
  color: white;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 30px;
  font-size: 1rem;
  letter-spacing: 1px;
  text-align: justify;
}

@media (max-width: 768px) {
}

/* for mobile screen media */

@media (max-width: 480px) {
  .temple_grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 5em;
  }
}
