
  .slider_container-1 {
    width: 100%;
   display: flex;
   justify-content: center;
   flex-wrap: wrap;
    margin-top: 100px;
    margin-bottom: 100px;
   
  }
  
  .slider_container-1 img{
    width: 210px;
    height: 210px;
  }
  
  .gallery-second-row img{
    margin-top: 30px;
  }
  @media (max-width:480px){
    .gallery-first-row{
      display: flex;
      flex-direction: column;
    }
    .gallery-first-row img{
    margin-bottom: 20px;
    }
    .gallery-second-row{
      display: flex;
      flex-direction: column;
    }
  }