.pacakages-page-container {
  overflow-x: hidden;
  overflow-y: hidden;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .pacakages-page-container {
    overflow-y: hidden; /* Hide vertical scrollbar on small screens */
    overflow-x: hidden;
  }
}
@media (max-width: 480px) {
  .pacakages-page-container {
    overflow-y: hidden; /* Hide vertical scrollbar on small screens */
    overflow-x: hidden;
  }
  .pacakages-page-container button {
    overflow-y: hidden; /* Hide vertical scrollbar on small screens */
    overflow-x: hidden;
    margin: 10px;
  }
  .package-detail-cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

}
