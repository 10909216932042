.cards_grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(270px, 1fr));
  gap: 30px;
}

.cards_grid button {
  background-color: #f7921f;
  color: white;
  width: 125px;
  border: none;
  height: 40px;
  border-radius: 6px;
  font-size: 14px;
}
.cards_grid .card-title {
  text-align: center;
  color: var(--primary-color);
  font-size: 1.11rem;
  padding-bottom: 5px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-align: center;
}
.read_more {
  display: flex;

  justify-content: end;
  color: #f7921f;
}
.read_more:hover {
  display: flex;
  justify-content: end;
  color: #f7921f !important;
}
.card_body {
  padding-top: 1.2rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}
.blog_img {
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.2rem;
}
.temple_blog {
  display: flex;
  flex-direction: column;
}
.cards_grid .card {
  /* background-color: #e9ecef; */
  background-color: var(--card-color);
  border: 0px;
  border-radius: 18px;
  padding-top: 1.7rem;
  padding-left: 1.7rem;
  padding-right: 1.7rem;
  padding-bottom: 1.7rem;
}

.cards_grid .card_text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  text-align: justify;
  line-height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  margin: 0 !important;
}
.blog-img {
  width: 320px;
  height: 200px;
}
.cards_grid.card-img-top {
  border-radius: 10px !important;
}

/* .card-img-top {
  padding: 20px;
  padding-bottom: 0px;
} */

@media (max-width: 868px) {
  .cards_grid {
    grid-template-columns: repeat(2, minmax(270px, 1fr));
  }
}

@media (max-width: 608px) {
  .cards_grid {
    grid-template-columns: repeat(1, minmax(270px, 1fr));
  }
  .card-title {
    font-size: 16px; /* Adjust font size for smaller screens */
  }
}
