.booking-information-subcontainer {
  position: relative;
  font-family: "Poppins" !important;
}

.form_grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.booking-information-banner {
  background-color: #f7921f;
  color: white;
  font-family: "Poppins";
  width: 195px;
  height: 50px;
  margin-top: 50px;
  margin-left: 200px;
  position: sticky;
  font-family: "Poppins";
}

.booking-information-banner-content {
  display: flex;
  padding-top: 10px;
}
.booking-information-banner-content img {
  width: 35px;
  margin-right: 10px;
  margin-left: 10px;
}
.booking-information-subcontainer label,
input {
  display: flex;
}
.booking-information-subcontainer input {
  margin-bottom: 20px;
}
.booking-information-subcontainer label {
  color: #f7921f;
  font-size: 12px;
  padding-bottom: 3px;
}
.booking-information-subcontainer form {
  margin-right: 0px;
}
input {
  width: 100%;
  height: 2.8rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
}
input::placeholder {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.3);
}

input::file-selector-button {
  font: inherit;
  height: 100%;
}
.form_grid_1 {
  min-width: calc(90% / 2);
}
.form_grid_2 {
  min-width: calc(90% / 2);
}
.form_grid_2 select{
  width: 100%;
  min-width: calc(90% / 2);
  width: 100%;
  height: 2.8rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
}

button.upload-button {
  height: 2.6rem;
  width: 2.8rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  background-color: transparent;
}
button.upload-button img {
  width: 14px;
  height: 14px;
}
.other-members{
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap:1em;
}

.modal_heading{
  font-size: 1.6rem;
  text-transform: capitalize;
  text-align: center;
}
.pop_modal_header{
  position: absolute;
  right: 0px;
  top: 20px;
  right: 30px;
  z-index: 99;
  cursor: pointer;
}
.pop_modal_boday{
  padding: 4rem;
  position: relative;
 
}
.pop_modal_boday2{
  padding: 0.7rem;
  position: relative;
 
}
.img_title{
  text-align: center;
  font-weight: 600;
  margin-top: 0.5em;
}
.image_grid{
  display: grid;
  box-sizing: border-box;
  gap: 2em;
  margin-top: 2em;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.modal_desc{
  font-size: 1.15rem;
  text-transform: capitalize;
  text-align: center;
}

.other-members button {
  height: 2.8rem;
 
  width: 100%;
  background-color: #f7921f;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  margin-top: 2em;

}
.cancellation {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  margin-bottom: 0.6rem;
}
.terms {
  display: flex;
  align-items: center;
  font-size: 0.5rem;
  margin-bottom: 0.3rem;
}
.terms img {
  width: 10px;
  height: 10px;
  margin-right: 10px;
}
.cancellation img {
  width: 15px;
  height: 15px;
  margin-right: 30px;
}
.cancellation p {
  font-weight: 500;
  letter-spacing: 1px;
}
.cancellation-policy {
  font-weight: 600;
  color: #f7921f;
  padding-bottom: 20px;
  font-size: 20px;
}
.billing-policy.row {
  margin-top: 50px;
}
.visa-policy {
  font-weight: 600;
  color: #f7921f;
  padding-bottom: 20px;
  font-size: 20px;
  padding-top: 20px;
}
.visa {
  display: flex;
}
.visa img {
  width: 30px;
  margin-right: 40px;
  max-width: 50px;
}
.visa p {
  font-weight: 500;
  letter-spacing: 1px;
}
.cancellation-visa {
  border-right: 1px solid var(--card-color);
}
.billing-details {
  font-weight: 600;
  color: #f7921f;
  padding-bottom: 20px;
  font-size: 20px;
}
.transport h3 {
  font-weight: 600;
  color: #f7921f;
  padding-bottom: 20px;
  font-size: 12px;
}
.transport p {
  color: black;
  font-weight: 500;
  letter-spacing: 1px;
  padding-right: 120px;
}
.accomodation {
  margin-bottom: 1em;
}
.accomodation h3 {
  font-weight: 600;
  color: var(--primary-color);
  /* padding-bottom: 20px; */
  font-size: 1.05rem;
}
.accomodation p {
  color: black;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 0.9rem;
  padding: 0px;
  margin: 0px;
}
.meals h3 {
  font-weight: 600;
  color: #f7921f;
  padding-bottom: 20px;
  font-size: 12px;
}
.meals p {
  color: black;
  font-weight: 500;
  letter-spacing: 1px;
  padding-right: 120px;
}
.police-squad h3 {
  font-weight: 600;
  color: #f7921f;
  padding-bottom: 20px;
  font-size: 12px;
}
.room_img{
  width: 100%;
  height: 100%;
}
.police-squad p {
  color: black;
  font-weight: 500;
  letter-spacing: 1px;
  padding-right: 120px;
}

.ticl_container {
  display: flex;
  justify-content: start;
  align-items: center;
}

.tick svg {
  width: 2.9rem;
}

.thank_you_cards {
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 1.5em;
  margin-top: 1.5em;
}
.thank_you_total {
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 1em;
  margin-top: 1.5em;
  display: flex;
  justify-content: space-between;
  color: var(--primary-color);
  font-weight: 600;
  width: 40%;
}
.thank_you_desc {
  margin-bottom: 0.5em;
  font-size: 0.9rem;
}
.thank_you_main_title {
  font-weight: 700;
  margin-bottom: 0.8em;
  font-size: 1.1rem;
}
.thank_you_title {
  font-weight: 600;
  margin-bottom: 0.5em;
  font-size: 1rem;
}
.total {
  padding: 20px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
}

.confirm-booking {
  width: 100%;
  text-align: center;
}

.confirm-booking-button {
  border: none;
  padding: 10px 20px;
  background-color: #f7921f;
  color: white;
  font-size: 1.5rem;
  border-radius: 8px;
  margin: 2em auto;
}
.proceed-button {
  border: none;
  padding: 10px 20px;
  background-color: #f7921f;
  color: white;
 align-content: end;
  font-size: 1.5rem;
  border-radius: 8px;
  margin: 2em auto;
}
@media (max-width: 768px) {
  .booking-information-subcontainer {
    width: 100%;
    margin-left: 0;
  }
  .billing-details {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    color: #f7921f;
    padding-bottom: 20px;
    font-size: 20px;
  }
  .terms {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.6rem;
    margin-bottom: 0.1rem;
  }
  .visa-policy{
    display:flex;
    flex-direction: row;
    margin-bottom: 0.1rem;
  }
  .other-members{
    display: flex;
    flex-direction: column;
    gap:0.3em;
  }
  .other-members2{
    display: flex;
    flex-direction: column;
    gap:0.3em;
  }
 
 
}

@media (max-width: 608px) {
  .pop_modal_boday{
    padding: 2rem;
    position: relative;
   
  }
  .img_title{
    text-align: center;
    font-weight: 600;
    margin-top: 0.5em;
  }
  .image_grid{
    display: grid;
    box-sizing: border-box;
    gap: 2em;
    margin-top: 2em;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

