/* banner.css */

.banner-container {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/background.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
  overflow-x: hidden;
  max-height: 100% !important;
  overflow-y: hidden;
}

.banner-content {
  text-align: center;
  font-family: "Poppins" !important;
  color: white;
  padding: 200px 200px; /* Adjust horizontal padding for mobile devices */
  opacity: 1 !important;
}

.banner-content h1 {
  font-size: 40px;
  font-weight: 600;
  padding: 4px;
}

.banner-content p {
  padding-top: 15px;
  padding-bottom: 15px;
  line-height: 25px;
  letter-spacing: 1px;
  font-weight: 500;
}

.banner-content button {
  color: white;
  background-color: #f7921f;
  border-radius: 8px;
  border: none;
  width: 165px;
  height: 50px;
  font-family: "Poppins" !important;
  font-weight: 600;
}

@media (max-width: 768px) {
  .banner-container {
    height: 400px;
  }

  .banner-content {
    padding-top: 100px;
    padding-bottom: 20px; /* Adjust vertical padding for mobile devices */
  }

  .banner-content h3 {
    font-size: 30px;
  }

  .banner-content p {
    font-size: 14px;
    line-height: 20px;
  }

  .banner-content button {
    width: 140px;
    height: 40px;
  }
}

@media (max-width: 480px) {
  .banner-container {
    height: 400px; /* Adjust the height for smaller screens */
  }

  .banner-content {
    padding: 75px 20px 40px 20px; /* Adjust the padding for smaller screens */
    /* Adjust vertical padding for smaller screens */
  }

  .banner-content h3 {
    font-size: 35px; /* Adjust font size for smaller screens */
  }

  .banner-content p {
    font-size: 16px; /* Adjust font size for smaller screens */
    line-height: 30px;
  }

  .banner-content button {
    width: 120px; /* Adjust button width for smaller screens */
    height: 36px; /* Adjust button height for smaller screens */
    font-size: 14px; /* Adjust button font size for smaller screens */
  }
}
