 .sikhlearning-container{
    background-color: #F5F5F5!important;
    padding-bottom: 50px;
    padding-top: 50px;
}
.learning-content{
    padding-top: 30px;
    padding-bottom: 30px;
}
.learning-content p{
    color: #ff0000;
    font-family: 'Poppins';
    font-weight: bolder;
    letter-spacing: 1px;
    margin-bottom: 5px;
    padding-left: 100px;
}
.card-img-top{
  border-radius: 20px !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
}
.learning-content h1{
    text-align: left;
    text-decoration: none;
    color: #f7921f;
    font-family: 'Poppins';
    font-weight: 600;
    padding-left: 100px;
    padding-right: 125px;
}
.learning-content-1{
    color: black;
    font-weight: 400;
    line-height: 30px;
    font-family: 'Poppins';
    padding-top: 50px;
    padding-right: 125px!important;
    font-weight: 500;
}
.Card-2 .card-title {
    text-align: center;
    color: #f7921f!important;
    font-size: 15px;
    padding-bottom: 5px;
}


.card-title{
    font-family: 'Poppins';
    font-weight: 600;
}
.card-text{
    font-family: 'Poppins';
    font-weight:bold;
    font-size: 10px;
    line-height: 17px;
    text-align: justify;
}

.Card-2 .card{
    border: none!important;
    max-width: 18rem;
    margin: 0 auto;
    border-radius: 18px;
    margin-top: 50px;
}
.Card-2 .card-img-top{
    padding: 20px;
    padding-bottom: 0px;
    border-radius: 30px;
    height:230px;

}
 @media (max-width:768px) {
    .learning-content{
        padding: 0;
    }
    .Card-2 .card-title {
        text-align: center;
        color: #f7921f;
        font-size: 15px;
        padding-bottom: 5px;
      }
    
      .Card-2 .card {
        max-width: 18rem;
        background-color: #e9ecef;
        border-radius: 18px;
      }
    
      .card-title {
        font-family: 'Poppins';
        font-weight: 600;
      }
    
      .card-text {
        font-family: 'Poppins';
        font-weight: bold;
        font-size: 10px;
        line-height: 17px;
      }
    
      .card {
        border: none!important;
      }
      .col-sm-12 {
        display: flex;
        justify-content: center;
      }
} 
  
 @media (max-width: 480px) {
    .sikhlearning-container{
        margin-top: 30px;
    }
    .learning-content{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .learning-content-1{
      padding-right: 20px!important;
    }
    .sikhlearning-container .row{
        margin-top: 40px!important;
    }
    .sikhlearning-container .learning-content p{
        font-size: 16px;
        line-height: 30px;
    }
  .sikhlearning-container .learning-content h1{
        font-size: 40px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
    }
    .sikhlearning-container .learning-content-1 p{
        text-align: justify!important;
        padding: 0px 20px 0px 20px;
        font-size: 15px;
       
    }
    .Cards .card {
      max-width: 16rem; 
    }
  
    .card-title {
      font-size: 16px; 
    }
  
    .card-text {
      font-size: 12px!important; 
      line-height: 20px!important;
    }
  
  } 