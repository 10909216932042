body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Hide horizontal overflow */
    width: 100%;
  }
  
  /* Ensure full width for all components */
  .gallery-page-container {
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  
  @media (max-width: 768px) {
    /* Hide horizontal overflow for screens up to 768px */
    body {
      overflow-x: hidden;
    }
  }
  
  @media (max-width: 480px) {
    /* Hide horizontal overflow for screens up to 480px */
    body {
      overflow-x: hidden;
    }
    .gallery-page-container {
      width: 100%;
      overflow-x: hidden;
      overflow-y: hidden;
    }
  }
  