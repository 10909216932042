body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    width: 100%;
  }
  
  /* Ensure full width for all components */
  .result-page-container{
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media (maxwidth:480px){
    .result-page-container{
      width: 100%;
      overflow-x: hidden;
      overflow-y: hidden;
    }
   
  }