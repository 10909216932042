.banner_content {
  text-align: center;
  font-family: "Poppins" !important;
  color: white;
  padding-top: 150px;
  opacity: 1 !important;
}

.banner_container {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/background.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 380px;
  background-position: top;
  position: relative;
}
.banner_subcontainer {
  background-color: var(--primary-color);
  color: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: max-content;
  text-transform: capitalize;
  padding: 15px 20px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
@media (max-width: 768px) {
  .banner_subcontainer {
    background-color: var(--primary-color);
    color: #fff;
    font-size: 0.6rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    width: max-content;
    text-transform: capitalize;
    padding: 10px 10px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
  }
}