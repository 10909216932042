/* homepage.css */

/* Apply box-sizing to all elements */
* {
  box-sizing: border-box;
}

/* Remove margin and padding from body */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* Ensure full width for all components */
.About-Container {
  overflow-x: hidden;
  overflow-y: hidden;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .About-Container {
    overflow-y: hidden; /* Hide vertical scrollbar on small screens */
  }
}
