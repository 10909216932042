.banner6-container {
  background-image: url('../../assets/Group1782.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 370px;
    overflow-x: hidden;
  }
  .banner6-content {
    text-align: center;
    font-family: 'Poppins'!important;
    color: white;
    padding-top: 150px;
    opacity: 1 !important;
  }
  .banner6-content p{
    color: #fff;
    font-family: 'Poppins';
    font-weight: bolder;
    letter-spacing: 1px;
    margin-bottom: 5px;
  }
  .banner6-content h1{
    font-weight: 600;
    font-size: 50px;
  }
  
  
@media (max-width: 480px) {
  .banner6-container {
    height: 400px; /* Adjust the height for smaller screens */
    background-position: center;
  }

  .banner6-content {
    padding: 135px 20px 40px 20px; /* Adjust the padding for smaller screens */
     /* Adjust vertical padding for smaller screens */
  }

  .banner6-content p {
    font-size: 16px; /* Adjust font size for smaller screens */
  }
  .banner6-contenth1{
    font-size: 40px;
  }

}