.sikhabout-content {
  margin-top: 70px !important;
  margin-right: 70px !important;
  margin-left: 50px !important;
  font-family: "Poppins";
}
.sikhabout-content span {
  color: #ff0000;
  font-weight: bolder;
  font-size: 20px;
  text-align: justify;
}

.first {
  position: relative;
}

.second {
  background-color: #e4e4e4;
  margin-top: 150px;
  font-family: "Poppins";
  margin-left: -65px !important;
  left: 400px;
  height: 340px;

  border-radius: 18px;
}
.place h5 {
  color: #f7921f;
  font-family: "Poppins";
  font-weight: bold;
  font-size: 10px;
  line-height: 17px;
  font-size: 15px;
}
.guru {
  max-width: 100%;
  height: 100%;
  width: 650px;
}
.another-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
}

.image-with-text {
  display: flex;
  align-items: center;
}

.image-with-text img {
  max-width: 100px;
  margin-right: 10px;
  margin-left: 100px;
}

.text {
  flex: 1;
}

.text h3 {
  font-size: 18px;
  margin: 0;
}

.text p {
  font-weight: 500 !important;
  font-family: "Poppins" !important;
  color: black;
  font-size: 15px;
  line-height: 25px;
  text-align: justify;
  padding-right: 70px;
}

/* Media Query for screens smaller than md (768px) */

/* Media Query for screens smaller than md (768px) */
@media (max-width: 767px) {
  .first .col-5 {
    width: 100%;
  }

  .first.col {
    display: flex;
    width: 100%;
  }

  .col-7 {
    margin-top: 0% !important;
    margin-left: 0%;
    width: 100% !important;
  }

  .sikhabout-content {
    margin-top: 0% !important;
    margin-left: 0;
  }

  .second {
    position: static !important;
    margin-top: 0px !important;
    margin-bottom: 400px !important;
    left: 0;
    right: 0;
    padding-right: 70px;
  }

  
  .row.second {
    margin-left: 0;
    margin-right: 0;
  }
}

/* Media Query for screens smaller than 480px */
@media (max-width: 480px) {
  .col-7 {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .image-with-text img {
    max-width: 100px;
    margin-right: 10px;
    margin-left: 70px;
  }
  .sikhabout-content {
    margin-left: 0;
    padding: 40px 20px;
  }

  .another-container {
    padding-top: 40px;
  }

  .row.second {
    margin-left: 0;
    margin-right: 0;
    position: relative;
    height: 485px;
  }

  .guru {
    max-width: 100%;
    height: 400px;
    width: 100%;
  }
  .sikhabout-content .humanity {
    font-size: 16px;
    font-weight: 400;
    color: black !important;
  }
}
