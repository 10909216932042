
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;1,100&display=swap');

body {
  margin: 0!important;
  padding: 0!important;
  }
  /* Add this CSS to your form.css or wherever you have your CSS */
.form-container {
  margin-top:0!important;
  padding-top: 0!important;
  height: 80vh!important;
  background-color: #F5F5F5;
  
}
.questions h1{
  font-family: 'Montserrat'!important;
  margin-left: 50px!important;
  font-weight: 800!important;
}
form{
  margin-right: 100px;
}
.form-container .row{
  margin: 0;
  padding: 0;
  margin-bottom: 25px;
}

.col {
  margin: 0;
  padding: 0;
}
.r4 button{
  color: white;
  background-color: #f7921f;
  border-radius: 8px;
  border: none;
  width: 145px;
  height: 50px;
  font-family: 'Poppins';
  font-weight: 600;
}

.form-container .form-r{
 padding-top: 100px;
}
.arrival-date .form-control::placeholder {
margin-left: 0px!important;
}
@media screen and (max-width:480px){
  form{
    margin-left: 50px;
  }
  .form-r .col-12{
    margin-bottom: 35px!important;
  }
  
  .questions h1{
    font-size: 35px;
    padding-bottom: 20px;
  }
  .form-container{
   
    height: 84vh!important;
  }
  .form-r{
margin: 0;
padding: 0;
  }
  .form-r .r1{
    margin-bottom: 0px;
  }
  .form-r .r2{
    margin-bottom: 0px;
  }
  .form-r .r3{
    margin-bottom: 0px;
  }
.r3 textarea{
    height: 7px!important;
  }
.r4 .col button{
  margin-left: 0px!important;
}
}