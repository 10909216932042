.footer {
  color: #f7921f;
  background-color: #333333;
  font-family: "Poppins";
  max-width: 100% !important;
}
.footer-1-content {
  margin-top: 15px;
  text-align: left;
}
.gallery {
  margin-top: 30px;
}
.footer-info {
  padding-left: 50px;
  font-size: 0.8rem;
  padding-top: 15px;
}
.footer-info-1 {
  padding-right: 50px;
  padding-top: 15px;
  font-size: 0.8rem;
}
.footer .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.gallery img {
  width: 100px;
  margin-right: 10px;
  border-radius: 8px;
}
.subcontainer {
  background-color: #f7921f;
  color: black;
  font-family: "Popppins" !important;
  max-width: 100% !important;
}
.subcontainer p {
  font-family: "Poppins";
}
.container-3 {
  width: 90%;
  margin: 0 auto;
  padding: 20px;
}
.design-first {
  font-weight: 300 !important;
  color: white;
}
.design-second {
  font-weight: 300 !important;
  color: white;
}
.design-third {
  font-weight: 300 !important;
  color: white;
}

.design1 svg {
  fill: var(--primary-color);
}
.design1 {
  /* font-size: 20px; */
  margin-right: 20px;
  font-size: 1.5em;
}
.quick-contact {
  margin-left: 50px;
}
.connect {
  margin-left: 50px;
}

/* Media Query for screens smaller than lg (992px) */
@media (max-width: 991px) {
  .gallery img {
    width: 80px;
    margin-right: 10px;
  }
  .quick-contact {
    text-align: center;
  }
  .connect {
    text-align: center;
  }
}

/* Media Query for screens smaller than md (768px) */
@media (max-width: 767px) {
  .gallery img {
    width: 60px;
    margin-right: 5px;
  }
  .photo-row {
    flex-wrap: wrap;
  }
}

@media (max-width: 480px) {
  .gallery img {
    width: 100px;
    margin: 5px;
  }
  .subcontainer {
    display: flex !important;
    flex-wrap: wrap !important;
    flex-direction: column !important;
  }
  .photo-row {
    flex-wrap: wrap;
    margin-left: 30px;
  }
  .quick-contact,
  .connect {
    margin-left: 0;
    margin-top: 10px;
  }
  .footer-info {
    margin: 0px;
    font-size: 8px;
    text-align: center;
    padding: 10px 0px 0px 0px;
  }
  .footer-info-1 {
    font-size: 0.7rem;
    padding: 0px;
    text-align: center;
  }
  .design-second {
    margin-right: 38px;
  }
  .design-third {
    margin-left: 45px;
  }
  .gallery .footer-1-content {
    text-align: center;
  }
}
