.book-tour-container {
  font-family: "Poppins";
}
.swiper-container-hotel {
  width: 100%;
  overflow: hidden;
}

.loading_grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 30px;
}
.modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 2em; */
  background-color: var(--primary-color);
  padding: 0.5em 1em;
  color: #fff;
  text-transform: uppercase;
}

.ReactModal__Content.ReactModal__Content--after-open {
  padding: 0px !important;
  border: 0px !important;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.35) !important;
}
.mb-4 {
  margin-bottom: 1rem !important;
}
.f-14 {
  font-size: 0.8rem;
}

.modal-heading {
  font-size: 1.2rem;
  font-weight: 700;
}

.f-13 {
  font-size: 0.65rem;
}
.drop-down-container {
  position: relative;
  display: flex;
}

.drop-down-container svg {
  width: 0.6rem;
  fill: var(--primary-color);
}
.me-1 {
  margin-right: 0.25rem !important;
}

.drop-down-room {
  border: solid rgba(0, 0, 0, 0.35);
  border-width: thin;
  display: flex;
  width: 7rem;
  justify-content: space-between;
  align-items: center;
  padding: 7px 10px;
  font-size: 0.5em;
}
.drop-down-icon svg {
  width: 0.8rem;
  margin-left: 5px;
  margin-right: 3px;
}
.text-default-bold {
  font-weight: 700;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.rate-plan-details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5em;
  gap: 1.5em;
}
.mb-5 {
  margin-bottom: 3rem !important;
}

.room-details-container-left {
  width: 35%;
}
.room-details-container-left img {
  width: 100%;
  min-height: 170px;
  background-size: cover;
}

.room-details-container-right {
  width: 60%;
}
.room_drop_down {
  position: absolute;
  top: 2.5em;
  background: #fff;
  min-width: 100%;
  right: 0;
  margin: 0;
  padding: 0;
  z-index: 9999;
  max-height: 150px;
  overflow-y: scroll;

}
.room_drop_down li {
  list-style: none;
  margin: 0;
  padding: 10px 20px;
  font-size: 0.7rem;

}

.room_drop_down li:hover {
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
}
.modal_header {
  padding: 0.9em 1.3em;
}
.modal_header button {
  background-color: transparent;
  border: none;
  color: #fff;
}
.modal_header .title {
  font-size: 1.1rem;
  font-family: "Poppins";
}
.modal_boday {
  padding: 20px 30px;
}
.banner {
  font-family: "Poppins";
  width: -moz-fit-content;
  width: fit-content;
  background-color: var(--primary-color);
  color: white;
  padding: 11px 20px;
  font-size: 1rem;
}

.banner img {
  width: 1.9rem;
  height: 1.5rem;
  margin-right: 0.3rem;
}

.hotel-booking {
  position: relative;
  font-family: "Poppins" !important;
  padding: 1.5rem 2.5rem;
}

.hotel-booking .card {
  border-radius: 18px;
  background-color: var(--card-color);
  border: 0px;
  min-height: 30em;
  border-radius: 18px;
  padding-top: 1.7rem;
  padding-left: 1.7rem;
  padding-right: 1.7rem;
  padding-bottom: 1.7rem;
}
.hotel-booking h1.card-text {
  font-size: 1rem;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}
.card_title {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 23px;
  overflow: hidden;
  min-height: 2em;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 1em;
}
.mx-05 {
  margin-right: 0.2em;
  margin-left: 0.2em;
}
.card_star {
  font-size: 1em;
  color: var(--primary-color);
}
.card_price {
  color: var(--primary-color);
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 23px;
}
.card_desc {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-bottom: 1em;
}
.hotel-booking .card-img-top {
  width: 100% !important;
  height: 9.5rem;
  border-radius: 18px !important;
  -webkit-border-radius: 18px !important;
  -moz-border-radius: 18px !important;
  -ms-border-radius: 18px !important;
  -o-border-radius: 18px !important;
}
.hotel-svgs {
  margin-bottom: 20px;
  max-height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hotel-svgs img {
  margin-right: 9px;
  margin-left: 9px;
  width: 1.15rem;
}
.room-details-container {
  margin-bottom: 2em;
}
.select-hotel button {
  background-color: #f7921f;
  color: white;
  width: 125px;
  border: none;
  height: 40px;
  border-radius: 6px;
  font-size: 14px;
}


.book-tour-container{
  font-family: 'Poppins';
  width: 100%;
  
}
.car_card .car-banner{
  background-color: #f7921f;
  color:white;
  font-family: 'Poppins';
  width: 195px;
  height:50px;
  margin-top: 50px;
  margin-left: 200px;
  position: sticky;
  font-family: 'Poppins';
}
.swiper-container-car {
  width: 100%;
  overflow: hidden;
}
.car_card .car-banner-content{
  display: flex;
  padding-top: 10px;
  
}
.car_card .car-banner-content img{
  width: 35px;
  margin-right: 10px;
  margin-left: 10px;
}

.car-booking .swiper-container{
  margin-top: 0px!important;
  margin-bottom: 0px!important;
  margin-left: 0px!important;
}

.car-booking{
  position: relative;
  font-family: "Poppins" !important;
  padding: 1.5rem 2.5rem;
}

.car-booking .card-img-top {
  width: 100% !important;
  height: 9.5rem;
  border-radius: 18px !important;
  -webkit-border-radius: 18px !important;
  -moz-border-radius: 18px !important;
  -ms-border-radius: 18px !important;
  -o-border-radius: 18px !important;
}
.car-booking .card{
  border-radius: 18px;
  background-color: var(--card-color);
  border: 0px;
  border-radius: 18px;
  padding-top: 1.7rem;
  padding-left: 1.7rem;
  padding-right: 1.7rem;
  padding-bottom: 1.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.car-booking h1.card-text{
  font-size: 14px;
  font-weight: 600;
}
.car-booking h3.card-text{
  color: #f7921f;
}

/* .car-svgs{
  margin-bottom: 20px;
} */
.car-svgs img{
  /* margin-right: 30px; */
  width: 15px;
}
.police_booking{
  padding: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.police_grid{
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  place-items: center;
  gap: 30px;
}


.p_title{
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 0.7em;
}
.p_desc{
  color: var(--primary-color);
  font-size: 400;
  margin-bottom: 0.2em;
}
.police_car_inner{
  min-height: 6rem;
  font-size: 0.75rem;
}

.child-minus, .child-plus, .minus, .plus, .room-minus, .room-plus {
  cursor: pointer;
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  color: var(--primary-color);
  border: 1px solid;
  border-radius: 100px;
  cursor: pointer;
}
.qty input:disabled {
  background-color: transparent;
}
.qty{
  justify-content: center;
  align-items: center;
}
.qty input {
  border: 0;
  width: 2%;
  color: #000;
  display: inline-block;
  vertical-align: top;
  line-height: 25px;
  padding: 0 10px;
  background-color: transparent;
  min-width: 55px;
  text-align: center;
}
.p_price{
  font-weight: 700;
  font-size: 0.8rem;
  margin-bottom: 0.7em;
}
.police_car {
  width: 100%;
  text-align: center;
  flex-direction: column;
  background: var(--card-color);
  padding: 2em 2.1rem;
  border-radius: 18px;
  min-height: 10.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}
.car-text{
  font-size: 10px;
  margin-right: 10px;
  padding-left: 2px;
}
.selected_car button{
  background-color:red; 
  color: white;
  width: 125px;
  border: none;
  height: 40px;
  border-radius: 6px;
  font-size: 14px;  
}
.select-car button{
  background-color:#f7921f; ;
  color: white;
  width: 125px;
  border: none;
  height: 40px;
  border-radius: 6px;
  font-size: 14px;
}

.selected-hotel button {
  background-color: red;
  color: white;
  width: 125px;
  border: none;
  height: 40px;
  border-radius: 6px;
  font-size: 14px;
}
.hotel-booking .swiper-button-prev:hover,
.hotel-booking .swiper-button-next:hover {
  background-color: #007bff; /* Hover background color */
  color: #fff; /* Hover text color */
}
@media (max-width: 768px) {
  .police_booking{
    padding: 1rem;
    display: flex;
    justify-content:center;
    align-items:flex-start;
  }
  .police_grid{
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    place-items: center;
    gap: 30px;
  }
}